.navbar-brand img{
  width: 300px;
}
body{
  background-color: #FFFFFF94 !important;
}
*:focus {
  box-shadow: none !important;
}
.App-header.sticky-top {
  transition: all 0.5s;
  box-shadow: 0px 2px 15px rgb(0 0 0 / 22%);
}
.theme-color{
  color: #F0A202 !important;
}
.theme-color-2{
  color: #293241 !important;
}

.top-bar .container{
 border-bottom: 1px solid #ece9e6;
}

.top-bar .row{
  padding: 15px 0 !important;
}
.top-bar .list-group-item{
  background: transparent;
  border: 0;
  font-size: 14px;
}
.top-bar a{
  color: #293241;
  text-decoration: none;
}
.top-bar svg{
  color: #F0A202 !important;
}
#home{
  scroll-margin-top: 0;
  padding: 100px 0 ;
}
#about,
.monetization,
#services,
#contact{
  scroll-margin-top: 80px;
}
.container{
  overflow: hidden;
}


.theme-color.nav-link{
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 400;
}
body .bg-scroll .bg-body-tertiary{
  background-color: #fff !important;
  transition: all .3s ease-in-out !important;
}
body .bg-body-tertiary{
  --bs-bg-opacity: 1;
  background-color: #FFFFFF94 !important;
  padding: 20px 0;
}
.get-started a.theme-color.nav-link,
.get-started a.theme-color.nav-link:hover {
  color: #fff !important;
}
.offcanvas.offcanvas-end.show .theme-color.nav-link.item--active{
  color: #030017 !important;
}
.theme-color.nav-link:hover {
  color: #F0A202 !important;
  /* background: #3d66e1 !important; */
}
.theme-color.nav-link{
  text-transform: uppercase !important;
}
.offcanvas.offcanvas-end[aria-modal="true"]  .get-started{
  display: none;
}
.button-navbar a{
  display: flex;
  align-items: center;
  width: 150px;
  justify-content: space-evenly;
  border-radius: 15px;
  padding: 10px 30px;
  background: #293241 !important;
  color: #fff !important;
  border-radius: 30px;
}
.navbar .nav-link{
  padding: 13px 20px !important;
  color: #293241 !important;
  font-family: 'Archivo', sans-serif;
  text-transform: uppercase !important;
  font-weight: 600;
  font-size: 12px;
}
.item--active.nav-link{
  color: #F0A202 !important;
}
.heroSection h1{
  font-size: 50px;
  font-weight: 700;
  font-family: 'Archivo', sans-serif;
}
.heroSection .hero-left-col{
  padding-right: 100px !important; 
}
.heroSection .row{
  position: relative;
  z-index: 1;
}
.hero{
  position: relative;
  background-color: transparent;
  background-image: linear-gradient(0deg, #DE873126 10%, #FFFFFF94 100%);
}
.hero .elementor-shape {
  overflow: hidden;
  position: absolute;
  left: 0;
  width: 100%;
  line-height: 0;
  direction: ltr;
  bottom: -1px;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  z-index: 0;
}

.hero .svg{
  width: calc(250% + 1.3px);
  height: 221px;
  z-index: -1;
  display: block;
  position: relative;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}
.hero .elementor-shape .elementor-shape-fill {
  fill: #fff;
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  -webkit-transform: rotateY(0deg);
  transform: rotateY(0deg);
}

.typetext p{
  margin: 30px 0;
  font-family: 'Archivo', sans-serif;
}
.btn-cs{
  font-family: 'Archivo', sans-serif;
  background-color: #293241 !important;
  color: #fff !important;
  border: 0 !important;
  padding: 15px 40px !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  border-radius: 50px !important;
  transition: all .3s ease-in-out !important;
  text-transform: uppercase;
}
.btn-cs:hover{
  transform: translateY(-8px);
}
.btn-cs-m{
  font-family: 'Archivo', sans-serif;
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
  fill: #fff;
  color: #fff;
  background-color: #FD9E0100;
  border-radius: 30px;
  padding: 0;
  margin-left: 20px;
}
@keyframes elementor-animation-bob {
  0% {
      transform: translateY(-8px)
  }

  50% {
      transform: translateY(-4px)
  }

  100% {
      transform: translateY(-8px)
  }
}

@keyframes elementor-animation-bob-float {
  100% {
      transform: translateY(-8px)
  }
}

.solo-img img:hover{
  animation-name: elementor-animation-bob-float, elementor-animation-bob;
  animation-duration: .3s,1.5s;
  animation-delay: 0s,.3s;
  animation-timing-function: ease-out,ease-in-out;
  animation-iteration-count: 1,infinite;
  animation-fill-mode: forwards;
  animation-direction: normal,alternate
}
.text-gray{
  color: #9198a6 !important;
  font-size: 16px;
  font-family: 'Heebo', sans-serif; 
  margin: 30px 0;
}


.statistical{
  padding: 50px 0;
  background: #fff;
}
.statistical h2{
  font-weight: 700;
  font-family: 'Archivo', sans-serif;
}
.statistical .choose-map svg{
  font-size: 40px;
}

.statistical .choose-map h2{
  color: #293241;
  font-family: "Archivo", Sans-serif;
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 10px;
}



.about-heading{
  max-width: 800px;
  margin: auto;
}
#about h2{
  font-family: "Archivo", Sans-serif;
  font-weight: 700;
  font-size: 32px;
}
#about .sub-heading{
  font-family: 'Heebo', sans-serif;
  font-weight: 300;
}
#about{
  padding: 50px 0 ;
}
#about .about-subheading-heading{
  font-size: 18px;
  font-weight: 600;
}

.company-together .elementor-shape-top {
  top: -1px;
  overflow: hidden;
  position: absolute;
  left: 0;
  width: 100%;
  line-height: 0;
  direction: ltr;
}

.company-together h2{
  font-size: 52px;
  font-family: "Archivo", Sans-serif;
  font-weight: bold;
  width: 64%;
  margin: auto;
}
.company-together p{
  width: 70%;
  margin: 30px auto;
  font-size: 20px;
  line-height: 37px;
}

.company-together{
  position: relative;
  padding: 120px 0 100px;
  background-image: url("../public/assets/img/hometwo-bannerbg-5-1.png");
  background-position: top center;
  background-repeat: no-repeat;
  background-size: 120%;
  color: #fff;
}
.company-together .container{
  margin-top: 50px;
}
.company-together .ct-content{
  max-width: 1000px;
  margin: auto;
}

.company-together .elementor-shape-bottom {
  bottom: -1px;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  overflow: hidden;
  position: absolute;
  left: 0;
  width: 100%;
  line-height: 0;
  direction: ltr;
}

.company-together .elementor-shape-bottom  svg {
  display: block;
  width: calc(100% + 1.3px);
  position: relative;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  height: 18px;
}

.company-together .btn-cs{
  font-size: 14px !important;
  font-weight: 600 !important;
  background: #f0a202 !important;
}
.company-together.elementor-shape {
  overflow: hidden;
  position: absolute;
  left: 0;
  width: 100%;
  line-height: 0;
  direction: ltr;
  top: -1px;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  z-index: 0;
}

.company-together .svg{
  width: calc(250% + 1.3px);
  height: 221px;
  z-index: -1;
  display: block;
  position: relative;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}
.company-together .elementor-shape .elementor-shape-fill {
  fill: #fff;
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  -webkit-transform: rotateY(0deg);
  transform: rotateY(0deg);
}




.affiliate-row h2{
  font-size: 32px;
  font-family: "Archivo", Sans-serif;
  font-weight: bold;
}



/* .card{
  padding: 30px;
  border: 1px solid #ff4f5a !important;
  background-color: #030017 !important;
}
.card .card-title{
  color: #fff;
  text-align: center;
  font-family: "Inter", Sans-serif;
}
.card .card-text{
  color: #9198a6;
  text-align: center;
  font-family: "Inter", Sans-serif;
}
.card .card-img-top{
  width: 130px;
  transition: all .3s ease-in-out !important;
}
.read-more{
  text-align: center;
}
.read-more a{
  color: #ff4f5a !important;
  font-size: 14px;
  font-family: "Inter", Sans-serif;
}

.card-img-top:hover {
  transition: all .3s ease-in-out !important;
  transform: translateY(-8px);
} */

.affiliate-row h2{
  font-size: 18px;
  font-weight: 700;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
  margin-top: 0;
}
.hover-box{
  text-align: center;
  margin-bottom: 20px;
}

.hover-box img{
  -webkit-transition: all .4s ease;
    transition: all .4s ease;
    position: relative;
    overflow: hidden;
}

.hover-box:hover img{
  opacity: 1;
    transform: scale(1.1);
}

.hover-box .text-gray {
  margin: 0;
  opacity: 0;
  -webkit-transition: opacity .4s ease;
  transition: opacity .4s ease;
  font-weight: 300;
}
.hover-box:hover .text-gray{
  opacity: 1;
}
.hover-box .about-heading{
  position: relative;
  background: #fff;
  background-color: #fff;
  border-radius: 10px;
  width: calc(100% - 40px);
  padding: 30px 35px;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
  height: 90px;
  margin: -20px auto 0;
  -webkit-box-shadow: -1.216px 6.894px 25px 0 rgba(0,0,0,.07);
  box-shadow: -1.216px 6.894px 25px 0 rgba(0,0,0,.07);
  width: 100%;
}
.hover-box:hover .about-heading{
  height: 28vh;
}

.hover-box:hover .about-heading::after,
.hover-box:hover .about-heading::before {
  opacity: 1 !important;
  -webkit-box-shadow: -1.216px 6.894px 25px 0 rgba(0,0,0,.07);
    box-shadow: -1.216px 6.894px 25px 0 rgba(0,0,0,.07);
}

.hover-box:hover .about-heading::after{
  bottom: -20px;
  width: calc(100% - 40px);
  z-index: -2;
}
.hover-box:hover .about-heading h2{
  color: #2575fc !important;
}

.hover-box .about-heading::after,
.hover-box .about-heading::before{
  position: absolute;
  content: "";
  bottom: -10px;
  left: 50%;
  height: 100%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: calc(100% - 20px);
  z-index: -1;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
  opacity: 0;
  border-radius: 10px;
  background-color: #fff;
}

#affiliate{
  padding: 100px 0;
  /* background: #fff; */
}
#affiliate h2{
  margin-bottom: 20px;
}

.slick-slide > div {
  margin: 0 10px;
  background: #D7D7D7;
  text-align: center;
}

.slick-slide > div > div {
  display: block !important;
}

.slick-slide img {
  display: block;
  width: 100%;
}
.statistical h2{
  margin-bottom: 50px;
}

.advertising-btn{
  display: flex;
    align-items: center;
    width: 150px;
    justify-content: space-evenly;
    border-radius: 15px;
    padding: 10px 30px;
    background: #F0A202 !important;
    color: #fff !important;
    border-radius: 30px;
    text-decoration: none;
    font-family: "Inter", Sans-serif;
    transition: all .3s ease-in-out !important;
}
.advertising-btn:hover{
    transition: all .3s ease-in-out !important;
    transform: translateY(-8px);
}
.advertising p{
  font-family: "Inter", Sans-serif;
}
.text-content{
  padding-left: 50px !important;
}
.advertising img{
  transition: all .3s ease-in-out !important; 
}

.advertising img:hover{
  animation-name: elementor-animation-bob-float, elementor-animation-bob;
  animation-duration: .3s,1.5s;
  animation-delay: 0s,.3s;
  animation-timing-function: ease-out,ease-in-out;
  animation-iteration-count: 1,infinite;
  animation-fill-mode: forwards;
  animation-direction: normal,alternate
}

.slick-slide img:hover {
  transform: scale(1.1);
  transition: all .3s ease-in-out !important; 
}
.slick-slide img {
  transition: all .3s ease-in-out !important; 
}

.scroll-to-top{
  background: #F0A202 !important;
}

#services{
  padding: 100px 0;
  position: relative;
  background-color: transparent;
    background-image: linear-gradient(180deg, #faede1 40%, #fff 100%); 
}

#services .elementor-shape-top {
  top: -1px;
}

#services .elementor-shape {
  overflow: hidden;
  position: absolute;
  left: 0;
  width: 100%;
  line-height: 0;
  direction: ltr;
}
#services .elementor-shape-top svg{
  width: calc(177% + 1.3px);
  height: 200px;
  transform: translateX(-50%) rotateY(180deg) !important;
}

#services  .elementor-shape svg {
  position: relative;
  display: block;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}
#services  .elementor-shape .elementor-shape-fill {
  fill: #fff;
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  -webkit-transform: rotateY(0deg);
  transform: rotateY(0deg);
}
#services .container{
  position: relative;
  z-index: 1;
}

.flex-services {
  display: flex;
  justify-content: center;
  align-items: self-start;
}

.flex-services img{
  width: 100px;
  margin-right: 20px;
  transition: all .3s ease-in-out !important; 
}
.flex-services img:hover{
  transition: all .3s ease-in-out !important; 
  transform: translateY(-8px);
}

.services-area{
  max-width: 700px;
  margin: auto;
}

.flex-services > div{
  width: 100%;
  margin-bottom: 30px;
}

.services-area p{
  margin: 30px 0;
}

.why{
  padding: 100px 0 50px;
}

.why-content{
  max-width: 700px;
  margin: auto;
}
.why{
  background-color: transparent;
  background-image: linear-gradient(180deg, #030017 0%, #00002E 100%);
}

.why .card{
  border: 1px solid #0D0063 !important;
  background: #04001B !important;
}



.monetization {
  padding: 100px 0;
}
.monetization .benefits{
  font-size: 18px;
  font-weight: 600;
}
.monetization h2{
  font-size: 32px;
  font-family: 'Archivo', sans-serif;
  margin-bottom: 50px;
}
.monetization .card-img-extra-paddng svg{
 font-size: 50px;
 color: #F0A202 !important;
}

.monetization .card-title{
  font-size: 20px;
  font-family: 'Archivo', sans-serif;
  font-weight: 600;
  margin: 30px 0;
}
.monetization .card{
  border: 0;
  box-shadow: 0px 5px 30px 0px rgba(239.99999999999997, 161.9999999999999, 1.9999999999999925, 0.2);
}
.monetization .pods > div{
  margin-bottom: 25px;
}

.monetization .card-body{
  padding: 40px;
}
.monetization .card-text{
  color: #000 !important;
  font-size: 16px;
  font-weight: 300;
  font-family: 'Heebo', sans-serif; 
}





.publisher img{
  transition: all .3s ease-in-out !important; 
}

.publisher img:hover{
  animation-name: elementor-animation-bob-float, elementor-animation-bob;
  animation-duration: .3s,1.5s;
  animation-delay: 0s,.3s;
  animation-timing-function: ease-out,ease-in-out;
  animation-iteration-count: 1,infinite;
  animation-fill-mode: forwards;
  animation-direction: normal,alternate
}

.publisher .list-group-item{
  display: flex;
  background: transparent;
  border: 0;
  padding: 0 ;
  margin-bottom: 20px;
}
.publisher .list-group-item svg{
  width: max-content;
  font-size: 20px;
  color: #F0A202 !important;
  padding: 4px 20px 0 0;
}
.col2-list {
  display: flex;
}
.publisher .btn-cs {
  font-size: 14px !important;
  font-weight: 600 !important;
}
.publisher .list-group {
  margin-top: 40px;
  margin-right: 10px;
}
.publisher .list-group-item h5{
  color: #fff;
  font-size: 16px;
}
.publisher .list-group-item p{
  font-size: 16px;
  font-weight: 300;
  font-family: 'Heebo', sans-serif; 
  color: #000;
}
.publisher .list-group-item > div {
  width: 100%;
}




.keep-touch{
  position: relative;
  padding: 100px 0;
  background-color: #FAEDE1;
}

.keep-touch .elementor-shape-top {
  top: -1px;
}

.keep-touch .elementor-shape {
  overflow: hidden;
  position: absolute;
  left: 0;
  width: 100%;
  line-height: 0;
  direction: ltr;
}

.keep-touch .elementor-shape-top svg {
  height: 59px;
}

.keep-touch .elementor-shape svg {
  display: block;
  width: calc(100% + 1.3px);
  position: relative;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}
.keep-touch .elementor-shape .elementor-shape-fill {
  fill: #fff;
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  -webkit-transform: rotateY(0deg);
  transform: rotateY(0deg);
}

.keep-touch  .list-group-item{
  display: flex;
  background: transparent;
  border: 0;
  padding: 0 ;
  margin-bottom: 20px;
}

.keep-touch  .list-group-item svg{
  width: max-content;
  font-size: 25px;
  color: #000 !important;
  padding: 0 20px 0 0;
}
.keep-touch  .list-group{
margin-top: 40px;
}
.keep-touch  .list-group-item h5{
  color: #fff;
  font-size: 16px;
}
.keep-touch  .list-group-item p{
  color: #000;
  font-size: 16px;
  font-weight: 300;
  font-family: 'Heebo', sans-serif;
}
.keep-touch  .list-group-item > div {
  width: 100%;
}


.keep-touch p{
  font-size: 16px;
  font-weight: 300;
  font-family: 'Heebo', sans-serif;
}
.keep-desc-2{
  font-size: 0.75rem !important;
  font-weight: 500 !important;
  text-transform: uppercase !important;
  font-family: 'Archivo', sans-serif !important;
}
.keep-desc{
  color: #9198a6;
  margin: 20px 0;
}
.keep-touch a{
  color: #ff4f5a;
  text-decoration: none;
}
.keep-touch h2{
  position: relative;
  color: #293241 !important;
  font-size: 1.5rem;
  font-weight: 400;
  font-family: 'Archivo', sans-serif;
}
.keep-touch h2.send-us{
  margin-bottom: 50px;
}
.keep-touch h2.send-us:after{
  content: "";
  position: absolute;
  bottom: -15px;
  left: 0;
  width: 60px;
  height: 5px;
  background: #cc9955;
}
.keep-touch .form-static{
  margin-top: 30px;
  /* padding: 40px; */
  /* border: 1px solid #0D0063; */
  /* background: #050021; */
}


.keep-touch .form-static input,
.keep-touch .form-static textarea{
  margin-bottom: 30px;
  background-color: #293241;
  border-radius: 20px;
  border: 0;
  color: #ededed;
  font-family: "Heebo", Sans-serif !important;
  font-size: 16px;
  font-weight: 300;
  padding: 6px 16px !important;
  min-height: 47px;
}
.keep-touch .form-static input::-webkit-input-placeholder{
  color: #ededed;
  opacity: 1;
  
}
.keep-touch .form-static input::-moz-placeholder{
  color: #ededed;
  opacity: 1;
  
}
.keep-touch .form-static input:-ms-input-placeholder{
  color: #ededed;
  opacity: 1;
  
}
.keep-touch .form-static input::-ms-input-placeholder{
  color: #ededed;
  opacity: 1;
  
}
.keep-touch .form-static input::placeholder{
  color: #ededed;
  opacity: 1;
  
}
.keep-touch .form-static label{
  color: #9198a6;
}

.keep-touch .form-static .cs-btn-form{
  background: #ff4f5a;
  width: 100%;
  border: 0;
  border-radius: 30px;
  padding: 15px 10px;
  font-family: "Inter", Sans-serif;
  font-weight: bold;
}
.keep-touch .form-static textarea::-webkit-input-placeholder{
  color: #ededed;
  opacity: 1;
  
}
.keep-touch .form-static textarea::-moz-placeholder{
  color: #ededed;
  opacity: 1;
  
}
.keep-touch .form-static textarea:-ms-input-placeholder{
  color: #ededed;
  opacity: 1;
  
}
.keep-touch .form-static textarea::-ms-input-placeholder{
  color: #ededed;
  opacity: 1;
  
}
.keep-touch .form-static textarea::placeholder{
  color: #ededed;
  opacity: 1;
  
}

.keep-touch .form-static .cs-btn-form{
  background-color: #cc9955 !important;
  color: #ffffff;
  border-color: #cc9955 !important;
  font-family: "Archivo", Sans-serif;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
}

footer{
  padding-top: 250px;
  background-color: transparent;
  background-image: linear-gradient(180deg, #0170b9 0%, #0159B9 100%);
  overflow: hidden;
}
.copyright p{
  margin-bottom: 0;
}
.copyright .container{
  padding: 2% 0;
  border-top: 1px solid #a0aaba2b;
}
.footer-link .list-group{
    border-radius: 0;
    padding: 0;
}
.footer-link .list-group .list-group-item{
  background: transparent;
  border: 0;
  padding: 0;
  margin-bottom: 40px;
}
.newsletter input{
  background: #fff;
    border-radius: 50px;
    padding: 10px 30px;
    width: 100%;
    min-height: 40px;
    font-family: "Heebo", Sans-serif;
    font-size: 16px;
    font-weight: 300;
    color: #293241;
}

.sm-icons{
  padding: 15px;
  background: #F0A202;
  color: #fff;
  margin: 0 10px;
  border-radius: 50px;
}
.sm-icons:first-child{
  margin-left: 0 !important;
}
.sm-icons:last-child{
  margin-right: 0 !important;
}

.sm-icons svg{
  font-size: 20px;
}

footer h3{
  margin-bottom: 20px;
}
.footer-desc{
  margin-bottom: 50px;
  color: #fff !important;
}

footer h6{
  color: #fff;
  font-weight: 600;
  font-size: 18px;

}
.link{
  padding: 0;
}

.link li a{
  color: #9198a6;
  text-decoration: none;
}
.link li{
  display: block;
  padding: 10px 0;
}

.link li a svg,
.ci-link svg{
  color: #F0A202 !important;
}
.ci-link{
  padding: 0;
}
.ci-link li{
  display: flex;
  margin: 20px 0;
}
.ci-link p,
.ci-link a{
  color: #fff;
  text-decoration: none;
}
.pl-3{
  padding-right: 50px !important;
}
.ci-link svg{
  margin-right: 10px;
}

footer .advertising-btn{
  width: 100%;
  padding: 10px;
  font-weight: bold;
  margin: 10px 0 !important;
}
.link li a svg, .ci-link svg {
  color: #ff4f5a;
}

footer p{
  color: #fff;
}
footer a{
  font-size: 16px;
  font-family: 'Archivo', sans-serif;
  font-weight: 400;
  margin: 30px 0;
  color: #fff !important;
}

.navbar-toggler.collapsed{
  border-color: rgb(240,162,2, 1) !important;
  outline: none;
}
.navbar-toggler.collapsed:active{
  outline: none;
}
.navbar-toggler-icon{
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(240,162,2, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
}
.bg-scroll .navbar-toggler.collapsed{
  border-color: rgb(240,162,2, 1) !important;
}

.bg-scroll .navbar-toggler-icon{
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(240,162,2, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
}
.canvas-logo{
  width: 150px;
}
.offcanvas.offcanvas-end[aria-modal="true"] .theme-color.nav-link{
  color: #000 !important;
}

.offcanvas.offcanvas-end.show .theme-color.nav-link.item--active {
  color: #F0A202 !important
}
.copyright p {
  font-size: 14px;
  font-weight: 300;
  font-family: 'Heebo', sans-serif;
}


footer .elementor-shape-top {
  top: -1px;
}
footer{
  position: relative;
}

footer .elementor-shape {
  overflow: hidden;
  position: absolute;
  left: 0;
  width: 100%;
  line-height: 0;
  direction: ltr;
}

footer .elementor-shape svg {
  width: calc(190% + 1.3px);
  height: 165px;
  display: block;
  position: relative;
  left: 50%;
  transform: translateX(-50%) rotateY(180deg);
}



footer .elementor-shape .elementor-shape-fill {
  fill: #FAEDE1;
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  -webkit-transform: rotateY(0deg);
  transform: rotateY(0deg);
}













@media only screen and (max-width: 1199px) {
  .heroSection h1 {
    font-size: 37px;
}
  
  .footer-link .list-group .list-group-item {
    margin-bottom: 50px;
  }
  
  body .bg-body-tertiary {
    border-bottom: 1px solid #fff;
}
}

@media only screen and (max-width: 991px) {
  #home{
    padding: 50px !important;
  }
  #about {
    padding: 0;
  }
  #affiliate {
    padding: 50px 0;
  }
  #advertising {
    padding: 50px 0 0;
  }
  #services {
    padding: 50px 0 0;
}
  .publisher {
    padding: 50px 0 0;
  }
  footer {
    padding-top: 175px;
}
  .why {
    padding: 50px 0 0;
  }
  .tools-feature {
    padding: 50px 0;
  }
  .choose-map {
    padding: 50px 0;
  }
  .keep-touch {
    padding: 25px 0 50px;
  }
  .heroSection h1 {
    font-size: 23px;
}
  footer .advertising-btn {
    justify-content: center;
  }
  #about h2 {
    font-size: 23px;
}
.company-together h2 {
  font-size: 23px;
  width: 100%;
}
.company-together p {
  width: 100%;
  margin: 20px auto;
  font-size: 16px;
  line-height: 37px;
}
.company-together {
  background-size: initial;
}
.heroSection .hero-left-col {
  padding-right: 0 !important;
}
}

@media only screen and (max-width: 767px) {
  #home {
    padding: 20px 0 !important;
}
.choose-map {
  padding: 20px 0;
}
  .heroSection > .row {
    flex-direction: column-reverse;
  }

  .heroSection h1 {
    font-size: 30px;
  }
  .footer-link .container {
    padding: 20px 20px 0 20px;
  }
  .keep-touch .form-static {
    padding: 20px;
  }
  .sm-icons {
    padding: 12px;
  }
  .navbar-brand img {
    width: 150px;
  }
  #home {
    padding: 100px 0 50px;
  }
  .about-heading h2{
    font-size: 20px;
  }
  #about {
    padding: 20px 0 20px;
}
#affiliate {
  padding: 20px 0;
}
#advertising {
  padding: 20px 0 0;
}
.statistical {
  padding: 40px 0 20px;
}
.company-together {
  padding: 20px 0 50px;
}
.col2-list {
  display: block;
}
.publisher .list-group {
  margin: 0;
}
.publisher .row{
  flex-direction: column-reverse;
}
.monetization {
  padding: 20px 0;
}
footer {
  padding-top: 60px;
}
footer .elementor-shape svg {
  width: calc(450% + 1.3px);
  height: 60px;
}
.keep-touch .elementor-shape svg {
  width: calc(200% + 1.3px);
}
.statistical h2 {
  margin-bottom: 20px;
}
.top-bar{
  display: none;
}
.text-content {
  padding-left: 10px !important;
}
#services {
  padding: 20px 0;
}
.publisher {
  padding: 20px 0;
}
.why {
  padding: 20px 0 0;
}
.tools-feature {
  padding: 20px 0;
}
.keep-touch {
  padding: 20px 0;
}
.footer-link {
  padding: 20px 0;
}
.about .card{
  margin-bottom: 20px;
}
.copyright p {
  text-align: center !important;
  margin: 20px 0;
  font-size: 12px;
}
.copyright .container {
  padding: 5% 0;
}
}